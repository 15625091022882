@import "./style/variables";

*{ 
  text-decoration: none;
}

.container-white {
  background: white;
  margin: 20px;
  padding: 40px;
  border-radius: 20px;
  width: 70%;
}

.container-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.h1-title{
  color: white; 
  text-align: center;
}

.swal2-styled.swal2-confirm{
  background-color: $bleu_reelIT;
}

.css-1wnsr1i{
  left : 58%!important
}

.css-70igu2{
  -webkit-align-items : stretch!important;
}
