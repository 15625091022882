.form-container {
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
}

.form-container .MuiButton-root {
  font-size: 13px;
}

.forgot-password-button {
  background: none;
  border: none;
  font-size: 15px;
  color: #23398E;
  cursor: pointer;
}

.login-container {
  max-width: 30%;
  margin: 0 auto;
}

/****************************************************/
/***************  MEDIA QUERIES   *******************/
/****************************************************/

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .login-container {
    max-width: 100%;
  }

  .form-container .MuiGrid-item {
    width: 100%;
  }

  .form-container .MuiOutlinedInput-input {
    padding: 12px 16px;
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .login-container {
    max-width: 100%;
  }

  .form-container {
    padding: 5px !important;
    width: 100% !important;
  }

  .form-container .MuiGrid-item {
    width: 100%;
  }

  .form-container .MuiButton-root {
    width: 100%;
    font-size: 11px;
  }

  .forgot-password-button {
    font-size: 13px;
  }
}
