@import "../style/variables.scss";

.layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: $bleu_comNetwork;
    .nav-layout {
    background-color: white;
    ul {
        display: flex;
        gap: 10px;
        justify-content: space-around;
        padding: 1rem 0;
        margin: 0;
        list-style-type: none;

        li {
            display: flex;
            align-items: center;
            color: $bleu_comNetwork;
            text-transform: uppercase;
            a {
            text-decoration: none;
            font-weight: 400;
            color: inherit;
        }
        a:hover {
            color:$bleu_clair;
            ;
        }
    }
    }
}
.page-container {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}
footer {
    display:flex;
    justify-content: center;
    background: white;
    color: $bleu_comNetwork;
    font-weight: bold;
}
}