@import "../style/variables";

.card {
  box-shadow: none !important;
  padding: 1rem;
  min-width : 100%!important;
  width : 100%
}
.card-grid {
  display: flex;
  justify-content: center;
  align-items: center;
}