.fc-media-screen {
    .fc-header-toolbar {
    display: flex;
    gap: 40px;
    .fc-toolbar-chunk:first-child > .fc-button-group {
        display: flex;
        gap:20px;
        button {
            border-radius: 20px;
            background-color: #1976d2;
            border-color:white;
            padding: 10px 20px;
        }
    }
    .fc-toolbar-chunk:last-child > .fc-button-group {
        display: flex;
        button {
            // border-radius: 20px;
            background-color: #1976d2;
            border-color:white;
            padding: 10px;
        }
    }

}
.fc-footer-toolbar > .fc-toolbar-chunk > button {
    background-color: #1976d2;
    border-color:white;
    padding: 10px 20px;
    border-radius: 20px;
}
}

.fc-view-harness > .fc-view > table > tbody > tr > td > .fc-scroller-harness > .fc-scroller > .fc-timegrid-body > .fc-timegrid-slots > table > tbody > tr > .fc-timegrid-slot-lane {
    cursor: pointer;
}

* > .fc-event-main {
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}