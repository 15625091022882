@import "../../../style/variables.scss";

.link_nav{
  text-decoration: none; 
  color: black;
}

.active{
  background-color: $bleu_reelIT ;
  text-decoration: none; 
  color: white;
  width: 100%;
}

.typography-nav-title{
  text-align: center;
  font-weight: 700!important;
  padding: 1rem 0;
}