.list-client {
    background-color: #fff;
    text-align: center;
    width: 30%;
    height: 50%;
    margin: auto;
    padding: 2%;
    box-shadow: 0 0 99px #216acc19;
    border-radius: 40px;
    
    .list-client-content {
        position: static;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
    }
    
    .header svg {
        transform: scale(1.5);
        cursor: pointer;
    }

    .title {
        margin-left: 5%;
        color: black;
    }

    .icone {
        margin-right: 5%;
    }

    .sub-content {
        max-width: 550px;
        margin-left: 10px;
    }

    .sub-content svg {
        transform: scale(1.2);
        cursor: pointer;
    }

    .list {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        font-weight: bold;
    }

    .boutons {
        display: flex;
        align-items: center;
        padding: 10px;
    }

    .client-name {
        display: flex;
        align-items: center;
    }

    .client-name div {
        margin-left: 15px;
    }

    .edit-user-button {
        margin-right: 10px;
    }
    
    .edit-user-button svg {
        margin: auto;
        display: block;
    }

    .list span {
        display: flex;
    }


    /* MODAL CONFIRMATION SUPPRESSION D'UN CLIENT */
      .modalClient {
        position: fixed;
        z-index: 100000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.5);
        align-items: center;
        justify-content: center;
        display: flex;
      }
      
      .modalClient-content {
        width: 100%;
        max-width: 50%;
        margin: auto;
        height: auto;
        padding: 0;
        font-family: 'Montserrat', sans-serif;
        background-color: #fff;
        border-radius: 40px;
      }
      
      .modalClient-header {
        background-color: #1F3C8B;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 20px;
        margin-bottom: 30px;
        color: white;
      }
      
      .modalClient-title {
        position: relative;
        left: 0px;
        text-align: center;
        padding: 5px 10px 5px 10px;
        border-radius: 10px;
        font-size: 20px;
        color: var(--blue-dark);
        margin: 0 auto 0;
        width: 100%;
        padding-left: 40px;
      }
      
      .modal-chart {
        text-align: center;
      }
      
      .modal-body {
        font-size: 24px;
        font-weight: 600;
        max-width: 90%;
        margin: auto;
      }
      
      .modal-body-contents {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
      }
      
      .modal-body-contents>div {
        margin: 10px auto;
      }
      
      .modal-body-contents input[type="number"] {
        color: var(--blue);
      }
      
      .modal-body-form {
        width: 100%;
        max-width: 500px;
      }
      
      .modal-close {
        border: none;
        background: none;
        margin-top: 3px;
      }
      
      .modalClient-content .buttons {
        justify-content: space-between;
        max-width: 500px;
        margin: 2em auto 2em;
        display: flex;
      }

      .logOutButtons {
        display: block;
        background: #1F3C8B;
        color: white;
        font-size: 20px;
        font-weight: bold;
        padding: 15px 45px;
        border-radius: 10px;
        width: auto;
        border: none;
      }
      
      /****************************************************/
      /***************  MEDIA QUERIES   *******************/
      /****************************************************/
      
      @media screen and (min-width: 1000px) and (max-width: 1900px) {
        .modalClient-content {
          max-width: 1180px;
          max-height: 755px;
        }
      
        .modal-body-form .dropdown {
          max-width: 90%;
          font-size: 16px;
          margin: 5px auto 5px auto;
        }
      
        .modal-title {
          font-size: 25px;
        }
    
        .modal-header {
          margin-bottom: 0px;
        }
      }
      
      @media screen and (min-width: 1000px) and (max-width: 1900px) {
        .modal-body {
          max-height: 580px;
          min-height: 5px;
        }
      }
      
      @media screen and (min-width: 1900px) and (max-width: 2500px) {
        .modalClient-content {
          max-width: 1200px;
          max-height: 1000px;
        }
      }
      
      @media screen and (min-width: 3000px) {
        .modalClient-content {
          min-width: 1800px;
          max-width: 1800px;
          min-height: 230px;
          max-height: 1200px;
        }
      }
}