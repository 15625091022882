.submenu-content {
    display: flex;
    justify-content: center;
    width: 100%;
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 5px auto;
    }
}